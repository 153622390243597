import type { LoaderFunctionArgs } from '@remix-run/node'
import { useTypedLoaderData } from 'remix-typedjson'
import heroStyles from '~/components-v2/hero/hero.css?url'
import { fundersLinks, humanLinks } from '~/components'
import styles from '~/styles/pages/home.css?url'
import quoteStyles from '~/components/quote-section/quote-section.css?url'
import sheetPreviewStyles from '~/styles/components/sheet-preview.css?url'
import {
  FooterDemoSection,
  G2Badges,
  ProductTour,
  fragmentLinks,
} from '~/fragments'
import { CallToAction } from '~/utils/a-b'
import { rudderStack } from '~/utils/rudder-stack.server'
import { H2, P, Stack } from '~/components-v2'
import { QuoteSection } from '~/components/quote-section'
import {
  CustomersMarquee,
  customersMarqueeLinks,
} from '~/components/shared/customers-marquee'
import { QuoteBuzzfeed, QuoteIncidentIo3 } from '~/config/quotes'
import { getBlogPosts } from '~/services/contentful/blog.server'
import { TbBolt, TbDeviceImac, TbUsersGroup } from 'react-icons/tb'
import { ProductFeatureCards } from './fragments/product-feature-cards'
import { CaseStudyCalloutCards } from './fragments/case-study-callout-cards'
import { SheetGridPreview } from '~/components/illustration/business-intelligence'
import {
  PostCollection,
  postCollectionLinks,
} from '~/components-v2/post-collection/post-collection'

const title = 'Business intelligence that speaks your language'
const description =
  'From spreadsheets to SQL to AI, Omni helps you confidently explore data with speed and accuracy — all in one place.'

export const links = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: heroStyles },
  { rel: 'stylesheet', href: quoteStyles },
  { rel: 'stylesheet', href: sheetPreviewStyles },
  ...fragmentLinks.productTour(),
  ...fragmentLinks.g2badges(),
  ...fragmentLinks.tradeOffsCircles(),
  ...fragmentLinks.footerDemoSection(),
  ...fundersLinks(),
  ...postCollectionLinks(),
  ...humanLinks(),
  ...customersMarqueeLinks(),
]
export { meta } from '~/utils'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { posts } = await getBlogPosts({ limit: 3 })
  rudderStack(request, title)
  return { posts, description, title }
}

export default function () {
  const loaderData = useTypedLoaderData<typeof loader>()
  const posts = 'posts' in loaderData ? loaderData.posts : []

  return (
    <>
      <header className="hero product-shot-inline home">
        <Stack as="hgroup" gap={8}>
          <h1 className="gradient-text">{title}</h1>
          <H2 color="text4" fontSize="xl">
            {description}
          </H2>

          <CallToAction />
        </Stack>

        <div className="product-imagery">
          <div className="gradients">
            <div className="vibes"></div>
            <div className="dawn"></div>
          </div>
          <img
            src="/images/pages/internal-analytics/hero/hero-dashboard.webp"
            alt="A product shot of Omni showing a workbook"
          />
          <img
            className="ai-helper"
            src="/images/pages/home/blobby-card.webp"
            alt=""
          />
          <SheetGridPreview />
        </div>
      </header>

      <CustomersMarquee />
      <section>
        <hgroup className="centered-section-title">
          <h3>Omni helps everyone work smarter with data</h3>
        </hgroup>

        <section className="grid cols3 call-out-cards">
          <div>
            <TbBolt color="var(--color-brand)" />
            <P>
              <strong>Every team</strong> makes better decisions with easy
              access to metrics — backed by data they can trust
            </P>
          </div>
          <div>
            <TbUsersGroup color="var(--color-success)" />
            <P>
              <strong>Data teams</strong> go beyond tactical requests and become
              efficient, strategic business partners
            </P>
          </div>
          <div>
            <TbDeviceImac color="var(--color-warn)" />
            <P>
              <strong>Product teams</strong> build beautiful, scalable
              customer-facing data products in weeks, not months
            </P>
          </div>
        </section>
      </section>

      <QuoteSection quote={QuoteBuzzfeed} />

      <ProductFeatureCards />

      <QuoteSection quote={QuoteIncidentIo3} />

      <ProductTour />

      <CaseStudyCalloutCards />

      <section className="wide">
        <h2 className="with-divider">Recent updates</h2>
        <PostCollection posts={posts} />
      </section>

      <FooterDemoSection />
      <G2Badges />
    </>
  )
}
